import axios from "axios"
import 'element-plus/es/components/message/style/css'
import { ElMessage } from 'element-plus'
// import { getCurrentInstance} from 'vue';


const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
});


instance.defaults.withCredentials = true;
instance.defaults.headers.post["Content-Type"] =  "application/x-www-form-urlencoded"; //
// expired
// const { proxy }  = getCurrentInstance();
// token  interceptors
instance.interceptors.request.use(config => {
    const token = sessionStorage.getItem("token");
    if (token) {
        config.Authorization = token;
    }
    return config;
})

axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        const code = response.data.code;
        const msg = response.data.msg;
        if (response.status === 200) {
            if (code === 403 && msg.includes('Permission denied')) {
                sessionStorage.removeItem('Application')
                window.location.href = "/sign-in";
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(error)
        if (error.message === 'Request failed with status code 500') {
            console.info("Internal Server Error");
            console.info("path:" + document.location.pathname);
            if (document.location.pathname !== '/' && document.location.pathname !== '/') {
                window.location.href = "/";
            }
        } else {
            // proxy.$message(
            //     {
            //         message: error.message,
            //         type: 'error'
            //     }
            // )
            ElMessage({
                message: error.message,
                type: 'error'
            })
        }
    }
);
const Axios = {
    post(url, data, params, headers) {
        return new Promise((resolve, reject) => {
            instance({
                method: 'post',
                url,
                data: data,
                params: params,
                headers
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, params) {
        return new Promise((resolve, reject) => {
            instance({
                method: 'get',
                url,
                params: params,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    put(url, data, params, headers) {
        return new Promise((resolve, reject) => {
            instance({
                method: 'put',
                url,
                data: data,
                params: params,
                headers
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    delete(url, params) {
        return new Promise((resolve, reject) => {
            instance({
                method: 'delete',
                url,
                params: params,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

export default Axios;
