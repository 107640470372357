/* eslint-disable no-undef */
<template>
  <section class="section-space feature-section">
    <div class="container">
      <!-- section heading -->
<!--      <SectionHeading classname="text-center" :text="featuredData.title" :content="featuredData.content" isMargin="mb-3"></SectionHeading>-->
      <SectionHeading classname="text-center" :text="featuredData.title" isMargin="mb-0"></SectionHeading>
      <div class="row g-4 g-md-5">
        <div class="col-sm-6 col-md-4 col-lg-4" v-for="item in featuredData.featuredList" :key="item.id">
          <div @click="navigateToPage(item.path)">
            <div class="item  item-full">
              <img :src="item.img" class="item-img-top d-none d-md-block" alt="featured image">
              <div class="item-title">
                {{ item.title }}
              </div>
              <div class="item-content">
                {{ item.content }}
              </div>
              <!--                            <div class="item-body p-4">-->
              <!--                                <h5 class="item-title">{{ item.title }}</h5>-->
              <!--                                <p class="small">{{ item.content }}</p>-->
              <!--                            </div>&lt;!&ndash; end card-body &ndash;&gt;-->
            </div><!-- end card -->
          </div>
        </div><!-- end col -->
      </div>
    </div><!-- .container -->
  </section><!-- end featured-creator-section -->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'Featured',
  methods:{
    navigateToPage(path){
      if(path === '/#contracts') {
        this.scrollToSection('contracts')
      } else {
        this.$router.push(path)
      }
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  // watch: {
  //   $route(to) {
  //     if (to.hash) {
  //       this.scrollToSection(to.hash.substr(1));
  //     }
  //   }
  // },
  data() {
    return {
      SectionData,
      featuredData: {
        title: 'HOW TO START',
        content: 'Here is the text description about the brand introduction.',
        featuredList: [
          {
            id: 1,
            img: require('@/images/home-icon_1.png'),
            title: 'SIGN UP',
            content: "Fill out the form and get ready to start mining within 1 minute.",
            path: '/sign-up'
          },
          {
            id: 2,
            img: require('@/images/home-icon_2.png'),
            title: 'CHOOSE YOUR CONTRACTS',
            content: 'We provide you with profitable and risk-free cloud mining contracts.',
            path: '/#contracts'
          },
          {
            id: 3,
            img: require('@/images/home-icon_3.png'),
            title: 'START EARNING',
            content: 'After purchasing the contract, daily profits will be promptly deposited into your account.',
            path: '/profile/deposit'
          },
          // {
          //   id: 4,
          //   img: require('@/images/thumb/art-4.jpg'),
          //   title: 'Tyronejkd Universe',
          //   content: 'After a sold-out Art Blocks drop, Martin is back with',
          //   path: '/item-details'
          // }
        ]
      },
    }
  }
}
</script>


<style scoped lang="scss">
.feature-section {
  padding: 20px;

  .item {
    width: 100%;
    height: 100%;
    //height: 170px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.1), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
    padding: 1rem;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      //background: #1C2A46;
      background: orange;

      .item-title {
        color: #FFFFFF;
      }
      .item-content{
        color: #FFFFFF;
      }
    }

    .item-img-top {
      width: 40px;
      height: 40px;
      //background: #9835FB;
      //border-radius: 5px;
    }

    .item-title {
      //height: 22px;
      font-size: 1rem;
      //font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #273444;
      //line-height: 22px;
      margin-top: 0.5rem;
      width: 100%;
      transition: all 0.5s ease;

    }

    .item-content {
      font-size: 0.875rem;
      margin-top: 0.375rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      //color: #8492A6;
      color: #273444;
      width: 100%;
      transition: all 0.5s ease;

      //line-height: 20px;
    }


  }
}

</style>
