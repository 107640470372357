import Axios from "../axios";
import { toForm, outErr } from "./apiConfig";
const url = "/api/account"

// export function login(data) {
//     Axios.post(url + "/sign-in", toForm(data)).then(res => {
//         out(res)
//     }).catch(() => {
//         outErr(out)
//     })
// }

export default {
    login(data, out) {
        Axios.post(url + "/login", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    logout(out) {
        Axios.get(url + "/logout").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    register(data, out) {
        Axios.post(url + "/registration", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    code(data, out) {
        Axios.post(url + "/code", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    getEmailCode(data, out) {
        Axios.post("/api/email/code", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

}
