<template>
    <section class="register-section section-space-b pt-4 pt-md-5 mt-md-3" >
            <div class="container">
                <div class="row align-items-center justify-content-center" v-loading="loading">
                    <div class="col-lg-6 mb-lg-0 d-none d-lg-block">
                        <img :src="registerData.img" alt="" class="img-fluid">
                    </div><!-- end col-lg-6 -->
                    <div class="col-lg-6 col-md-9">
                        <div class="section-head-sm">
                            <h2 class="mb-2" v-html=" registerData.title"></h2>
<!--                            <p>{{ registerData.subTitle }}</p>-->
                        </div>
                        <div class="form">
<!--                            <div class="form-floating mb-4">-->
<!--                                <input type="text" class="form-control" id="fullName" placeholder="Name">-->
<!--                                <label for="fullName">Full name</label>-->
<!--                            </div>&lt;!&ndash; end form-floating &ndash;&gt;-->
<!--                            <div class="form-floating mb-4">-->
<!--                                <input type="text" class="form-control" id="userName" placeholder="Username">-->
<!--                                <label for="userName">Username</label>-->
<!--                            </div>&lt;!&ndash; end form-floating &ndash;&gt;-->
                            <div class="form-floating mb-4">
                                <input type="email" class="form-control animated" id="emailAddress" placeholder="name@example.com" ref="email" v-model="data.email">
                                <label for="emailAddress">Email Address</label>
                            </div><!-- end form-floating -->
                            <div class="form-floating mb-4">
                                <input type="password" class="form-control animated password" id="password" placeholder="Password" ref="password" v-model="data.password">
                                <label for="password">Password</label>
                                <a href="password" class="password-toggle" title="Toggle show/hide password">
                                    <em class="password-shown ni ni-eye-off"></em>
                                    <em class="password-hidden ni ni-eye"></em>
                                </a>
                            </div><!-- end form-floating -->
                          <div class="form-floating mb-4">
                            <input type="password" class="form-control animated password" id="re-password" placeholder="Password" ref="reTypePassword" v-model="data.reTypePassword">
                            <label for="password">Confirm Password</label>
                            <a href="re-password" class="password-toggle" title="Toggle show/hide password">
                              <em class="password-shown ni ni-eye-off"></em>
                              <em class="password-hidden ni ni-eye"></em>
                            </a>
                          </div><!-- end form-floating -->
                          <div class="form-floating mb-4" v-if="showReferralCode===true">
                            <input type="text" class="form-control animated " disabled id="referralCode" placeholder="Affiliates Code" ref="referralCode" v-model="data.referralCode">
                            <label for="referralCode">Affiliates Code</label>
                            <!--                            <a href="password" class="password-toggle" title="Toggle show/hide pasword">-->
                            <!--                              <em class="password-shown ni ni-eye-off"></em>-->
                            <!--                              <em class="password-hidden ni ni-eye"></em>-->
                            <!--                            </a>-->
                          </div><!-- end form-floating -->
<!--                          <div class="form-floating mb-4">-->
<!--                            <input type="text" class="form-control animated" id="whatsapp" placeholder="WhatsApp (Optional)" ref="whatsapp"  v-model="data.whatsapp">-->
<!--                            <label for="whatsapp">WhatsApp (Optional)</label>-->
<!--&lt;!&ndash;                            <a href="password" class="password-toggle" title="Toggle show/hide pasword">&ndash;&gt;-->
<!--&lt;!&ndash;                              <em class="password-shown ni ni-eye-off"></em>&ndash;&gt;-->
<!--&lt;!&ndash;                              <em class="password-hidden ni ni-eye"></em>&ndash;&gt;-->
<!--&lt;!&ndash;                            </a>&ndash;&gt;-->
<!--                          </div>&lt;!&ndash; end form-floating &ndash;&gt;-->


                          <div>
                            <div class="mb-4" v-if="alert.show">
                              <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
                            </div>
                          </div>

                            <p class="mb-4 form-text" >
                              {{ registerData.termText }} <router-link style="font-weight: bold" to="/privacy">Privacy Policy</router-link> and <router-link style="font-weight: bold" to="/terms"> Terms</router-link>
                            </p>
                            <button class="btn btn-dark w-100" @click="register">{{ registerData.btnText }}</button>
<!--                            <span class="d-block my-4">— or sign up with —</span>-->
<!--                            <ul class="btns-group d-flex">-->
<!--                                <li class="flex-grow-1" v-for="(list, i) in registerData.btns" :key="i"><router-link :to="list.path" class="btn d-block" :class="list.btnClass"><em class="ni" :class="list.icon"></em> {{ list.title }} </router-link></li>-->
<!--                            </ul>-->
                            <p class="mt-3 form-text">{{ registerData.haveAccountText }} <router-link :to="registerData.btnTextLink" class="btn-link">{{ registerData.btnTextTwo }}</router-link></p>
                        </div>
                    </div><!-- end col-lg-6 -->
                </div><!-- end row -->
            </div><!-- end container -->
        </section><!-- end register-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import accountApi from "@/apis/accountApi";
import storage from "@/storage";
export default {
  name: 'RegisterSection',
  data () {
    return {
      SectionData,
      // register data
      registerData: {
        // img: require('@/images/thumb/remote.png'),
        img: require('@/images/tmp/sign-up.jpg'),
        // title: 'Sign Up to <span class="text-primary">Landrick!</span>',
        title: 'SIGN UP',
        subTitle: 'Further registration',
        termText: 'By signing up, you agree to our ',
        btnText: 'CREATE AN ACCOUNT',
        haveAccountText: "Already have an account?",
        btnTextTwo: ' SIGN IN',
        btnTextLink: 'sign-in',
        btns: [
          {
            title: 'Google',
            btnClass: 'bg-red-100 text-red g-btn',
            icon: 'ni-google',
            path: ''
          },
          {
            title: 'Facebook',
            btnClass: 'bg-blue-100 text-blue f-btn',
            icon: 'ni-facebook-f',
            path: ''
          },
          {
            title: 'Twitter',
            btnClass: 'bg-cyan-100 text-cyan t-btn',
            icon: 'ni-twitter',
            path: ''
          },
        ]
      },


      data: {
        email: "",
        password: "",
        reTypePassword: "",
        referralCode: "",
        code: "",
        whatsapp: '',
        source: '',
      },
      showReferralCode: false,
      disSend: false,
      emailCodeCountdownTime: 60,
      emailCodeCountdownInter: 0,
      alert: {
        show: false,
        text: ""
      },
      showSignupText: false,
      signup_text: 'Registration is successful!\nPlease activate your account through the email link.',
      loading: false
    }
  },
  watch: {
    showSignupText(val) {
      if (val != this.showSignupText & val == false) {
        this.$router.push("/sign-in")
      }
    }
  },
  mounted () {
    /*  ======== Show/Hide passoword ======== */
    function showHidePassword(selector){
        let elem = document.querySelectorAll(selector);
        if(elem.length > 0){
            elem.forEach(item => {
            item.addEventListener("click", function(e){
                e.preventDefault();
                let target = document.getElementById(item.getAttribute("href"));
                if(target.type == "password") {
                target.type = "text";
                item.classList.add("is-shown");
                }else{
                target.type = "password";
                item.classList.remove("is-shown");
                }
            });

            });
        }
    }

    showHidePassword(".password-toggle");

    // this.data.referralCode = this.$route.query.ref
    this.data.referralCode = new URLSearchParams(window.location.search).get('ref');
    if(this.data.referralCode && this.data.referralCode.length>0){
      console.log('referral code:'+this.data.referralCode)
      this.showReferralCode = true
    }
    this.data.source = sessionStorage.getItem('source') || ''
    if(this.data.source === '' || this.data.source === null ){
      const currentURL = window.location.href;
      this.data.source = new URL(currentURL).origin;
    }
    if (this.$route.query.code == 0) {
      this.data.email = this.$route.query.email
      this.$message({
        showClose: true,
        message: 'Verification code expired.',
        type: 'error',
        duration: 0
      });
    }

  },

  methods: {
    change_msg(msg) {
      msg.toString().replace(/\\n/g, ' ')
      return msg
    },
    register1() {
      // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-])[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/;
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/i;
      alert(passwordRegex.test("Abcd124%"))
      //Password must be a minimum of 8 characters and include at least one letter, one number, and one special character.
    },
    register() {
      const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/i;
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Sign up...',
      //   spinner: 'el-icon-loading',
      //   // background: 'rgba(0, 0, 0, 0.7)'
      // })
      this.loading = true;
      //判断是否可以进行登录操作
      if (this.data.email == "") {
        this.alert.show = true
        this.alert.text = "Email cannot be empty."
        console.log(this.$refs.email)
        this.$refs?.email.focus()
        // loading.close()
        this.loading = false;
        this.$refs?.email.classList.add('error');
        setTimeout(() => {
          this.$refs?.email.classList.remove('error')
        }, 3500)
      } else if (this.data.password == "") {
        this.alert.show = true
        this.alert.text = "Password cannot be empty."
        // loading.close()
        this.loading = false;
        this.$refs?.password.focus()
        this.$refs?.password.classList.add('error');
        setTimeout(() => {
          this.$refs?.password.classList.remove('error')
        }, 3500)
      } else if (this.data.reTypePassword == "") {
        this.alert.show = true
        this.alert.text = "Confirm Password cannot be empty."
        // loading.close()
        this.loading = false;
        this.$refs.reTypePassword.focus()
        this.$refs?.reTypePassword.classList.add('error');
        setTimeout(() => {
          this.$refs?.reTypePassword.classList.remove('error')
        }, 3500)
      } else if (this.data.reTypePassword !== this.data.password) {
        this.alert.show = true
        this.alert.text = "The passwords you have entered do not match."
        // loading.close()
        this.loading = false;
        this.$refs.reTypePassword.focus()
        this.$refs?.reTypePassword.classList.add('error');
        setTimeout(() => {
          this.$refs?.reTypePassword.classList.remove('error')
        }, 3500)
      } else if (!emailRegex.test(this.data.email)) {
        this.alert.show = true
        this.alert.text = "Invalid email."
        // loading.close()
        this.loading = false;
        this.$refs.email.focus()
        this.$refs?.email.classList.add('error');
        setTimeout(() => {
          this.$refs?.email.classList.remove('error')
        }, 3500)
      } else if (!passwordRegex.test(this.data.password)) {
        this.alert.show = true
        this.alert.text = "Password must be a minimum of 8 characters and include at least one letter and one number."
        // loading.close()
        this.loading = false;
        this.$refs.password.focus()
        this.$refs?.password.classList.add('error');
        setTimeout(() => {
          this.$refs?.password.classList.remove('error')
        }, 3500)
      }
          // else if (this.data.code == "") {
          //   this.alert.show = true
          //   //验证码不得为空
          //   this.alert.text = "Verification code cannot be empty."
      // }
      else {
        let data = JSON.parse(JSON.stringify(this.data))
        data.password = btoa(this.data.password)
        accountApi.register(data, res => {
          // loading.close()
          this.loading = false
          if (res.code != 200) {
            this.alert.show = true
            this.alert.text = res.msg
            this.$message.error(res.msg)
          } else {
            this.trackRegistrationEvent()
            //储存返回的用户id
            storage.localSet({ userId: res.data })
            this.$router.push("/profile/dashboard")
            // window.location.href="https://stavlin.shop"
            // this.signup_text = res.msg
            // this.showSignupText = !this.showSignupText;

            // sessionStorage.removeItem('source')
            // this.$router.push("/auth-login")
          }
        })
      }
    },
    trackRegistrationEvent(){
      if (window.gtag) {
        window.gtag ('event', 'registration', { event_name: 'registration'});
      }
    },

    sendCode(){
      if (this.data.email == "") {
        this.alert.show = true
        //邮箱地址不得为空
        this.alert.text = "Your email cannot be empty"
        return
      }
      let data = {
        email: this.data.email
      }
      const loading = this.$loading({
        lock: true,
        text: 'Send code...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      accountApi.getEmailCode(data, res => {
        loading.close()
        if (res.code != 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.alert.show = false
          this.alert.text = ''
          this.getEmailCodeCountdown()
        }
      })
    },
    getEmailCodeCountdown() {
      console.log(this.emailCodeCountdownTime)
      this.disSend = true
      if (this.emailCodeCountdownTime <= 0) {
        this.clearEmailCodeCountdown()
        return
      }
      this.emailCodeCountdownInter = setInterval(() => {
        if (this.emailCodeCountdownTime <= 0) {
          this.clearEmailCodeCountdown()
          console.log('stop')
          return
        } else {
          this.emailCodeCountdownTime --
        }
      }, 1000)
    },
    clearEmailCodeCountdown(){
      clearInterval(this.emailCodeCountdownInter)
      this.emailCodeCountdownTime = 60
      this.disSend = false
    }
  },



  unmounted() {
    this.clearEmailCodeCountdown()
  },
}
</script>


<style lang="scss" scoped>
.img-fluid{
  width: 540px;
}


.error{
  -webkit-animation: twinkling 1s infinite ease-in-out;
  transition: all 0.3s ease;
}

.animated{
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}
@-webkit-keyframes twinkling{
  0%{
    //opacity: 0.5;
    border-color: rgba(220, 53, 69,0.2);
  }
  20%{
    //opacity: 0.5;
    border-color: rgba(220, 53, 69,0.5);
  }
  40%{
    //opacity: 0.5;
    border-color: rgba(220, 53, 69,1);
  }
  60%{
    //opacity: 0.5;
    border-color: rgba(220, 53, 69,5);
  }
  80%{
    //opacity: 0.5;
    border-color: rgba(220, 53, 69,2);
  }
  100%{
    border-color: rgba(220, 53, 69,0.0);
  }
}
@keyframes twinkling{
  0%{
    border-color: rgba(220, 53, 69,0.5);
  }
  100%{
    border-color: rgba(220, 53, 69,1);
  }
}
</style>
