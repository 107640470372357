
export default {
    localSet(data) {
        //获取本地储存内容
        let application = localStorage.getItem("Application")
        if (application) {
            application = JSON.parse(application)
        } else {
            application = {}
        }
        //将两个对象进行合并储存
        Object.assign(application, data)
        //保存时间
        Object.assign(application, { exp: new Date().getTime() })
        localStorage.setItem("Application", JSON.stringify(application))
    },
    localGet(key) {
        //获取本地储存内容
        let application = localStorage.getItem("Application")
        if (application) {
            //获取对应键值
            application = JSON.parse(application)
            //检测是否过期
            if (application.exp - new Date().getTime() < 604800000)
                return application[key]
            else
                this.localDel(key)
        }
        return undefined
    },
    localDel(key) {
        //获取本地储存内容
        let application = localStorage.getItem("Application")
        if (application) {
            //删除对应键值
            application = JSON.parse(application)
            delete application[key]
            localStorage.setItem("Application", JSON.stringify(application))
        }
    },
    sessionSet(data) {
        //获取本地储存内容
        let application = sessionStorage.getItem("Application")
        if (application) {
            application = JSON.parse(application)
        } else {
            application = {}
        }
        //将两个对象进行合并储存
        Object.assign(application, data)
        sessionStorage.setItem("Application", JSON.stringify(application))
    },
    sessionGet(key) {
        //获取本地储存内容
        let application = sessionStorage.getItem("Application")
        if (application) {
            //获取对应键值
            application = JSON.parse(application)
            return application[key]
        }
        return undefined
    },
    sessionDel(key) {
        //获取本地储存内容
        let application = sessionStorage.getItem("Application")
        if (application) {
            //删除对应键值
            application = JSON.parse(application)
            delete application[key]
            sessionStorage.setItem("Application", JSON.stringify(application))
        }
    },
    cookieGet(key) {
        let name = key + "=";
        let ca = document.cookie.split(';');
        for (const element of ca) {
            let c = element.trim();
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return undefined;
    }
}