export const outErr = out => {
    out({
        code: 500,
        msg: "System Error"
    })
}

export const toForm = data => {
    let arr = []
    for (let key in data) {
        arr.push(key + "=" + data[key])
    }
    return arr.join("&")
}
