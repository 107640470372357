import Axios from "../axios";
import { outErr } from "./apiConfig";
const url = "/api/index"



export default {
    //查看钱包
    dashBoard(out) {
        Axios.get(url + "/dashboard").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    //获取汇率
    roe(alias, out) {
        Axios.get(url + "/roe", { alias }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    lTrans(out) {
        Axios.get(url + "/latestTrans").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    GetComments({
        pageNum, pageSize
    }, out) {
        Axios.get(url + "/comment/list", { pageNum, pageSize }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    SetComment(data, out) {
        Axios.post(url + "/comment", {...data.data}).then(res => {
            out(res)
        }).catch((err) => {
            console.log(err)
            outErr(out)
        })
    },
    GetBitcoin(source,out) {
        Axios.get(url + "/bitcoin?source="+source).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    CheckProxy(key,out){
        Axios.get(url + "/checkProxy?key=" + key).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }
}
