import Axios from "../axios";
import { outErr, toForm } from "./apiConfig";
const url = "/api/plan"



export default {
    //查看列表
    list({
        limitTime,
        name,
        pageNum,
        pageSize
    }, out) {
        Axios.post(url + "/list", {
            limitTime,
            name,
            pageNum,
            pageSize
        }, {}, {'Content-Type': 'application/json'}).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    relatedList({
                    limitTime,
                    name,
                    recommend,
                    pageNum,
                    pageSize
                }, out) {
        Axios.post(url + "/list", {
            limitTime,
            name,
            recommend,
            pageNum,
            pageSize
        }, {}, {'Content-Type': 'application/json'}).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },


    vipList({
             limitTime,
             name,
             pageNum,
             pageSize
         }, out) {
        Axios.post(url + "/vipList", {
            limitTime,
            name,
            pageNum,
            pageSize
        },{},{'Content-Type': 'application/json'}).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },






    //查看历史
    mine({
        pageNum,
        pageSize
    }, out) {
        Axios.get(url + "/mine", {
            pageNum,
            pageSize
        }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    buy({
        planId,
        password,
        quantity
    }, out) {
        Axios.post(url + "/buy", toForm({
            planId,
            password,
            quantity
        })).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    detail({planId}, out) {
        Axios.get(url + "/detail", {
            id:planId
        }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }
}
