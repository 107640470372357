<template>
    <router-link :class="classname" :style="style" :to="link">{{ text }}</router-link>
</template>

<script>
export default {
  name: 'ButtonLink',
  props: ['text', 'classname', 'link', 'style']
}
</script>

<style scoped lang="scss">
.sign-in{
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  &:hover{
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  }
}
.sign-up{
  background: linear-gradient(to right, #4CAF50, #45A049);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  &:hover{
    transform: translateY(-2px);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
  }
}


@media (max-width: 992px) {
  .sign-up,.sign-in {
    padding: 5px 20px;
  }
}

</style>
