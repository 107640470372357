export default {
    getDate(day) {
        // eslint-disable-next-line no-unused-vars
        let mouth = day / 30
        // if (mouth >= 1) {
        //     // return mouth.toFixed(2) + "Month(s)"
        //     return Math.floor(day) + " Day(s)"
        // } else {
        //     return Math.floor(day) + " Day(s)"
        // }
        // console.log('day:'+day)
        if (Math.floor(day) > 1) {
            return Math.floor(day) + " Days"
        } else {
            // console.log(day + " Day")
            return Math.floor(day) + " Day"
        }
    },
    getTime(d) {
        // let date = new Date(d)
        // return date.getFullYear() + "-" + ten(date.getMonth() + 1) + "-" + ten(date.getDate()) + " " + ten(date.getHours()) + ":" + ten(date.getMinutes()) + ":" + ten(date.getSeconds());
        // return date.getFullYear() + "-" + ten(date.getMonth() + 1) + "-" + ten(date.getDate()) + " \n" + ten(date.getHours()-12) + ":" + ten(date.getMinutes()) ;
        return formatDateTime(d)
    }
    // ,
    // getDate(d) {
    //     let date = new Date(d)
    //     return date.getFullYear() + "-" + ten(date.getMonth() + 1) + "-" + ten(date.getDate()) + " " + ten(date.getHours()) + ":" + ten(date.getMinutes()) + ":" + ten(date.getSeconds());
    // }
}

// eslint-disable-next-line no-unused-vars
function ten(val) {
    if (val.toString().length < 2) {
        return '0' + val
    } else {
        return val
    }
}

function formatDateTime(date) {
    if (date === '' || !date) {
        return ''
    }
    //'zh' 可换navigator.language
    // console.log(date)
    var utcDate = new Date(date).toLocaleString(navigator.language, {
        hour12: false,
        dateStyle: 'short',
        timeStyle: 'short',
        // timeZone: 'America/New_York'
        timeZone: 'UTC'
    })
    // date = date.split('.')[0].replace('T', ' ')
    utcDate = utcDate.replace(', 24', ' 00').replace(',', ' ')
    return utcDate
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat = "{y}-{m}-{d} {h}:{i}:{s}") {
    // console.log(time, cFormat)
    if (!time) {
        return null;
    }
    if (arguments.length === 0) {
        return null;
    }
    const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
    let date;
    if (typeof time === "object") {
        date = time;
    } else {
        if (typeof time === "string" && /^[0-9]+$/.test(time)) {
            time = parseInt(time);
        }
        if (typeof time === "number" && time.toString().length === 10) {
            time = time * 1000;
        }
        const utcTime = formatDateTime(time)
        // console.log("utc:" + utcTime)
        date = new Date(utcTime);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };
    // eslint-disable-next-line camelcase
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
            return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
    });
    // console.log("final:"+time_str)
    // eslint-disable-next-line camelcase
    return time_str;
}
