<template>
    <div class="container">
                <div class="section-space-sm">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 me-auto">
                            <div class="footer-item mb-5 mb-lg-0">
                                <!-- logo link -->
                                <LogoLink class="footer-logo-link" src="logo-light.png"></LogoLink>
                                <p class="my-4 footer-para" v-if="footerData.content">{{ footerData.content }}</p>
                                <ul class="styled-icon">
                                    <li><a href="https://twitter.com/SoHash_Official"> <img class="s-icon"  src="@/images/tmp/social-media.png" alt="twitter"></a></li>
                                    <li><a href="https://www.facebook.com/SoHashMining"><img class="s-icon" src="@/images/tmp/facebook.png" alt="facebook"></a></li>
                                    <li><a href="https://www.instagram.com/sohash_official/"><img class="s-icon" src="@/images/tmp/instagram.png" alt="ins"></a></li>
                                    <li><a href="mailto:info@sohash.com"><img class="s-icon" src="@/images/tmp/email.png" alt="email"></a></li>
                                    <li><a href="https://sohash.com/download"><img class="s-app" src="@/images/tmp/app-down.png" alt="app-download"></a></li>
<!--                                    <li><a href="#"><em class="icon ni ni-pinterest"></em></a></li>-->
                                </ul>
                            </div><!-- end footer-item -->
                        </div><!-- end col-lg-3 -->
                        <div class="col-lg-8">
                            <div class="row g-gs">
                                <div class="col-lg-4 col-md-4 col-sm-4" v-for="item in footerData.footerList" :key="item.id">
                                    <div class="footer-item">
                                        <h5 class="mb-4">{{ item.title }}</h5>
                                        <ul class="list-item list-item-s1">
                                            <li v-for="(list, i) in item.navList" :key="i">
                                              <a :href="list.path" v-if="list.path.indexOf('http')  != -1" target="_blank">{{ list.title }}</a>
                                              <router-link :to="list.path" v-else>{{ list.title }}</router-link>
                                            </li>
                                        </ul>
                                    </div><!-- end footer-item -->
                                </div><!-- end col -->
                            </div>
                        </div>
                    </div><!-- end row -->
                </div>
                <hr class="bg-white-slim my-0">
                <div class="copyright-wrap d-flex flex-wrap py-3 align-items-center justify-content-between">
<!--                  <p class="footer-copy-text py-2" v-html="copyTitle"></p>-->
                  <p class="footer-copy-text py-2">
                    <a href="https://find-and-update.company-information.service.gov.uk/company/10433730" target="_blank">
                      <img src="@/images/tmp/about.png" width="60">
                    </a>
                    © 2017-2024, SOHASH. All Rights Reserved.
                  </p>
                  <ul class="list-item list-item-s1 list-item-inline">
                      <li v-for="(list, i) in footerData.footerListTwo" :key="i"><router-link :to="list.path">{{ list.title }}</router-link></li>
                  </ul>
                </div><!-- end d-flex -->
            </div><!-- .container -->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'FooterSection',
  data () {
    return {
      SectionData,
      copyTitle:'© 2017-2024, SOHASH. All Rights Reserved.',
      footerData: {
        content: "Our dedicated team of experts, coupled with cutting-edge technology, enables us to curate a curated of top-tier Cloud Mining investment projects, ensuring that your investment journey is both rewarding and secure.",
        footerList: [
          {
            id: 1,
            title: 'LEARN',
            navList: [
              // {
              //   title: 'What is an NFT?',
              //   path: '/what-is-an-nft'
              // },
              {
                title: 'What is cryptocurrency?',
                path: '/what-is-cryptocurrency'
              },
              {
                title: 'What is web3?',
                path: '/what-is-web3'
              },
              // {
              //   title: 'Cryptocurrency Mining',
              //   path: 'https://****.com/cryptocurrency-mining'
              // },
            ]
          },
          {
            id: 2,
            title: 'RESOURCES',
            navList: [
              {
                title: 'Affiliates',
                path: '/affiliates'
              },
              {
                title: 'Help Center',
                path: '/help-center'
              },
              // {
              //   title: 'Reviews',
              //   path: '/reviews'
              // },

            ]
          },
          {
            id: 3,
            title: 'COMPANY',
            navList: [
              {
                title: 'About Us',
                path: '/about-us'
              },
              {
                title: 'Terms of Service',
                path: '/terms'
              },
              {
                title: 'Privacy Policy',
                path: '/privacy'
              },
            ]
          }
        ],
      },

    }
  }
}
</script>

<style scoped>
.s-icon{
  color: #1c2b46;
  width: 2.1rem;
  height: 2.1rem;
  line-height: 2.313rem;
  border-radius: 10%;
  background-color: #ffffff;
  display: block;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s;
}

.s-app{
  //background-color: #ffffff;
  margin-left: 20px;
  width: 8rem;
}

</style>