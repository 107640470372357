<template>
  <ul class="menu-list ms-lg-auto">
    <template v-for="(menu, index) in menuList" :key="index">
      <li class="menu-item " :class="{'has-sub': !menu.path}">
        <router-link :to="menu.path" class="menu-link" :class="{'active': currentPath === menu.path}" v-if="menu.path">
                <span class="menu-title">
                  {{ menu.title }}
                <img class="menu-link_line" :class="{'active': currentPath === menu.path}"
                     src="@/images/select_b_line.png" alt="">
                </span>
        </router-link>
        <a href="#" class="menu-link menu-toggle" :class="{'active': currentPath === menu.path}" v-else>
          {{ menu.title }}
          <!--                <img class="menu-item_line" :class="{'active': currentPath === menu.path}" src="../../images/select_b_line.png" alt="">-->
        </a>

        <div class="menu-sub menu-mega" v-if="!menu.path">
          <div class="menu-mega-row">
            <ul class="menu-list menu-list-mega">
              <li class="menu-item" v-for="nav in menu?.navList" :key="nav.id">
                <router-link :to="nav.path" class="menu-link" :class="{'active': currentPath === nav.path}">
                  {{ nav.title }}
                  <span :class="nav.badgeClass" v-if="nav.badge">{{ nav.badge }}</span>
                </router-link>
              </li>
            </ul>
            <ul class="menu-list menu-list-mega">
              <li class="menu-item" v-for="nav in menu?.navListTwo" :key="nav.id">
                <router-link :to="nav.path" class="menu-link" :class="{'active': currentPath === nav.path}">{{
                    nav.title
                  }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </template>


    <template v-for="(menu, index) in mobileNavList" :key="index">
      <li class="menu-item d-lg-none"
          :class="{'has-sub': !menu.path, active: currentPath.indexOf(menu.paterPath) != -1}">
        <router-link :to="menu.path" class="menu-link" :class="{'active': currentPath === menu.path}" v-if="menu.path">
                <span>
                  {{ menu.title }}
                <img class="menu-link_line" :class="{'active': currentPath === menu.path}"
                     src="../../images/select_b_line.png" alt="">
                </span>
        </router-link>
        <a href="#" class="menu-link menu-toggle" :class="{'active': currentPath === menu.path}" v-else>
          {{ menu.title }}
          <!--                <img class="menu-item_line" :class="{'active': currentPath === menu.path}" src="../../images/select_b_line.png" alt="">-->
        </a>

        <div class="menu-sub menu-mega" :style="{display: currentPath.indexOf(menu.paterPath) != -1 ? 'block' : ''}"
             v-if="!menu.path">
          <div class="menu-mega-row">
            <ul class="menu-list menu-list-mega">
              <li class="menu-item" v-for="nav in menu?.navList" :key="nav.id">
                <router-link :to="nav.path" class="menu-link" :class="{'active': currentPath === nav.path}">
                  {{ nav.title }}
                  <span :class="nav.badgeClass" v-if="nav.badge">{{ nav.badge }}</span>
                </router-link>
              </li>
            </ul>
            <ul class="menu-list menu-list-mega">
              <li class="menu-item" v-for="nav in menu?.navListTwo" :key="nav.id">
                <router-link :to="nav.path" class="menu-link" :class="{'active': currentPath === nav.path}">{{
                    nav.title
                  }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </template>
    <li class="menu-item">
      <a href="/download" class="menu-link">App</a>
    </li>
  </ul>
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'MenuList',
  data() {
    return {
      SectionData,
      menuList: [
        // btnText: 'Connect Wallet',
        // inputPlaceholderText: 'Search item here...',
        {
          title: 'HOME',
          path: '/',
          navList: [],
          navListTwo: []
        },
        {
          title: 'CONTRACTS',
          path: '/contracts',
          navList: []
        },
        {
          title: 'DASHBOARD',
          path: '/profile/dashboard',
        },
        {
          title: 'ABOUT US',
          path: '/about-us',
          navList: []
        },
        {
          title: 'AFFILIATES',
          path: '/affiliates',
        },
        {
          title: 'HELP CENTER',
          path: '/help-center',
          // navList: [
          //   {
          //     id: 9,
          //     title: 'Login',
          //     path: '/login'
          //   },
          //   {
          //     id: 10,
          //     title: 'Login v2',
          //     path: '/login-v2'
          //   },
          //   {
          //     id: 11,
          //     title: 'Register',
          //     path: '/register'
          //   },
          //   {
          //     id: 12,
          //     title: 'Contact',
          //     path: '/contact'
          //   }
          //
          // ],
          // navListTwo: [
          //   {
          //     id: 7,
          //     title: 'Create Single',
          //     path: '/create-single'
          //   },
          //   {
          //     id: 8,
          //     title: 'Create Multiple',
          //     path: '/create-multiple'
          //   },
          //   {
          //     id: 20,
          //     title: 'Home',
          //     path: '/home',
          //   }
          // ]
        },

      ],
      mobileNavList: [
        {
          title: 'PROFILE',
          paterPath: 'profile',
          // path: '/help-center',
          navList: [
            // {
            //   id: 9,
            //   title: 'DASHBOARD',
            //   path: '/profile/dashboard'
            // },
            {
              id: 10,
              title: 'MY BILLS',
              path: '/profile/my-bills'
            },
            // {
            //   id: 11,
            //   title: 'Register',
            //   path: '/register'
            // },
            // {
            //   id: 12,
            //   title: 'Contact',
            //   path: '/contact'
            // },
            {
              id: 3,

              title: 'DEPOSIT',
              path: '/profile/deposit'
            },
            {
              id: 4,

              title: 'WITHDRAW',
              path: '/profile/withdraw'
            },
            {
              id: 5,

              title: 'MY ORDERS',
              path: '/profile/my-order'
            },
            {
              id: 6,
              title: 'CONTRACTS',
              path: '/contracts'
            },
            {
              id: 7,
              title: 'AFFILIATES',
              path: '/profile/affiliates'
            },
            {
              id: 8,
              title: 'SETTINGS',
              path: '/profile/settings'
            },

          ],
        }
      ],
      offerSidebarData: {
        title: '',
        navList: [
          {
            id: 1,
            class: 'active',
            icon: 'ni-gift',
            title: 'DASHBOARD',
            path: '/profile/dashboard'
          },
          {
            id: 2,
            icon: 'ni-file-text',
            title: 'MY BILLS',
            path: '/profile/my-bills'
          },
          {
            id: 3,
            icon: 'ni-money',
            title: 'DEPOSIT',
            path: '/profile/deposit'
          },
          {
            id: 4,
            icon: 'ni-exchange',
            title: 'WITHDRAW',
            path: '/profile/withdraw'
          },
          {
            id: 5,
            icon: 'ni-camera',
            title: 'MY ORDERS',
            path: '/profile/my-order'
          },
          {
            id: 6,
            icon: 'ni-puzzle',
            title: 'CONTRACTS',
            path: '/contracts'
          },
          {
            id: 7,
            icon: 'ni-download-cloud',
            title: 'AFFILIATES',
            path: '/profile/affiliates'
          }
        ]
      },
      currentPath: this.$route.path
    }
  },
  watch: {
    $route(to) {
      console.log(to)
    }
  }
}
</script>

<style>

.menu-title {
  font-size: 16px;
}

@media screen and (min-width: 992px) {
  .menu-title {
    font-size: 14px;
  }

  .menu-link {
    padding: 1rem 0.525rem;
  }
}

@media screen and (min-width: 1200px) {
  .menu-title {
    font-size: 16px;
  }

  .menu-link {
    padding: 1rem 1.15rem;
  }
}

</style>
