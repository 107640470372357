<template>
<div  class="top-global-tip is-sticky">
  <div class="container">
    <div class="text">
      <MarqueeNew :duration="5000" :content="content"/>

    </div>
  </div>
</div>
</template>

<script>
import MarqueeNew from "@/components/common/MarqueeNew";

export default {
  name: "TopGlobalTip",
  props: ['isTransparent'],
  components: {MarqueeNew},
  data () {
    return {
      content: 'Here is a placeholder map of the text pushed at the top'
    }
  }

}
</script>

<style scoped lang="scss">
.top-global-tip{
  height: 45px;
  background: #1C2A46;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1000;
  .text{
    font-size: 16px;
    //font-family: ZapfDingbatsITC;
    color: #FFFFFF;
    line-height: 45px;
    text-align: center;
  }
}
</style>
