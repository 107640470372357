<template>
  <section class="login-section section-space-b pt-4 pt-md-5 mt-md-3">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 mb-5 mb-lg-0 d-none d-lg-block">
          <img :src="loginData.img" alt="" class="img-fluid">
        </div><!-- end col-lg-6 -->
        <div class="col-lg-6">
          <div class="section-head-sm">
            <h2 class="mb-1">{{ loginData.title }}</h2>
            <p>{{ loginData.subTitle }}</p>
          </div>
          <form action="#">
            <div class="form-floating mb-4">
              <input type="email" ref="email" class="form-control " placeholder="Email" v-model="data.email" @focus="inputfocus($event)">
              <label for="emailAddress">Email</label>
                            <div class="invalid-feedback">
                              Your email cannot be empty.
                            </div>

            </div><!-- end form-floating -->
            <div class="form-floating mb-4">

              <input type="password" ref="password" class="form-control   password" id="password" placeholder="Password" v-model="data.password" @focus="inputfocus($event)">
              <label for="password">Password</label>
              <a href="password" class="password-toggle" title="Toggle show/hide pasword">
                <em class="password-shown ni ni-eye-off"></em>
                <em class="password-hidden ni ni-eye"></em>
              </a>
                            <div class="invalid-feedback">
                              {{passwordText}}
                            </div>
            </div><!-- end form-floating -->
            <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="logMeIn" v-model="data.remember">
                <label class="form-check-label form-check-label-s1" for="logMeIn"> Remember me </label>
              </div>
                                              <router-link to="/forgot-password" class="btn-link form-forget-password">Forgot Password?</router-link>
            </div>


            <div class="col-lg-12" style="margin-bottom: 10px;" v-if="alert.show">
              <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
            </div>

            <button class="btn btn-dark w-100" type="button" :disabled="loading" @click="login()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
              {{ loginData.btnText }}
            </button>
            <!--                            <span class="d-block my-4">— or login with —</span>-->
            <!--                            <ul class="btns-group d-flex">-->
            <!--                                <li class="flex-grow-1" v-for="(list, i) in loginData.btns" :key="i"><router-link :to="list.path" class="btn d-block" :class="list.btnClass"><em class="ni" :class="list.icon"></em> {{ list.title }} </router-link></li>-->
            <!--                            </ul>-->
            <p class="mt-3 form-text">{{ loginData.haveAccountText }}
              <router-link :to="loginData.btnTextLink" class="btn-link">{{ loginData.btnTextTwo }}</router-link>
            </p>


            <!--                          <p class="mt-3  text-center">-->
            <!--                            <router-link :to="loginData.btnTextLink" class="form-text">-->
            <!--                              {{ loginData.haveAccountText }}-->
            <!--                            </router-link>-->

            <!--                          </p>-->

          </form>
        </div><!-- end col-lg-6 -->
      </div><!-- end row -->
    </div><!-- end container -->
  </section>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import accountApi from "@/apis/accountApi";
import { ElMessage } from 'element-plus'
import storage from "@/storage";

export default {
  name: 'LoginSection',
  data() {
    return {
      data: {
        email: "",
        password: "",
        remember: false,
      },
      alert: {
        show: false,
        text: ""
      },
      SectionData,
      loginData: {
        // img: require('@/images/thumb/remote.png'),
        img: require('@/images/tmp/sign-up.jpg'),
        title: 'WELCOME TO SOHASH',
        subTitle: 'Sign in to continue',
        btnText: 'SIGN IN',
        haveAccountText: "Don't have an account?",
        btnTextTwo: 'Sign Up',
        btnTextLink: 'sign-up',
        btns: [
          {
            title: 'Google',
            btnClass: 'bg-red-100 text-red g-btn',
            icon: 'ni-google',
            path: ''
          },
          {
            title: 'Facebook',
            btnClass: 'bg-blue-100 text-blue f-btn',
            icon: 'ni-facebook-f',
            path: ''
          },
          {
            title: 'Twitter',
            btnClass: 'bg-cyan-100 text-cyan t-btn',
            icon: 'ni-twitter',
            path: ''
          },
        ]
      },
      loading: false,
      passwordText: ''
    }
  },
  mounted() {
    /*  ======== Show/Hide passoword ======== */
    function showHidePassword(selector) {
      let elem = document.querySelectorAll(selector);
      if (elem.length > 0) {
        elem.forEach(item => {
          item.addEventListener("click", function (e) {
            e.preventDefault();
            let target = document.getElementById(item.getAttribute("href"));
            if (target.type == "password") {
              target.type = "text";
              item.classList.add("is-shown");
            } else {
              target.type = "password";
              item.classList.remove("is-shown");
            }
          });

        });
      }
    }

    showHidePassword(".password-toggle");


    let data = storage.localGet("user")
    if (data) {
      this.data = data
    }
    // console.log(this.$route.query.code)
    if (this.$route.query.code == 1) {
      ElMessage({
        showClose: true,
        message: "Account already activated.",
        type: "success",
        duration: 0
      })


    }

  },
  methods: {
    inputfocus(e) {
      // console.log(e)
      e.srcElement.classList.remove('is-invalid');
      this.alert.show = false;
    },
    login() {
      // console.log(this)
      //判断是否可以进行登录操作
      let status = false
      if (!this.data.email) {
        this.$refs['email'].classList.add('is-invalid')
        // this.alert.show = true
        status = true
        //邮箱地址不得为空
        // this.alert.text = "Your email cannot be empty"
      } else {
        status = false
      }

      if (!this.data.password) {
        this.$refs['password'].classList.add('is-invalid')
        // this.alert.show = true
        //密码不得为空
        this.passwordText= "Password cannot be empty"
        status = true
      } else if (this.data.password.length < 8 || this.data.password.length > 20) {
        this.$refs['password'].classList.add('is-invalid')
        // this.alert.show = true
        //密码长度必须在8~20位之间
        this.passwordText = "Password length must be between 8 and 20 bits"
        status = true
      } else {
        status = false
      }
      if(status) return;
      // const loading = Loading.service({
      //   lock: true,
      //   text: 'Sign in...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      let loginForm = JSON.parse(JSON.stringify(this.data))
      loginForm.password = btoa(loginForm.password)
      //登录请求
      this.loading = true;
      accountApi.login(loginForm, res => {
        // console.log(res)
        // loading.close()
        // eslint-disable-next-line no-unreachable
        this.loading = false;
        if (res.code != 200) {
          this.alert.show = true
          this.alert.text = res.msg
          // ElMessage({
          //   message: res.msg,
          //   type: 'error'
          // })
          // this.$message({
          //   message: res.msg,
          //   type: 'error'
          // })
        } else {
          //储存返回的用户id
          storage.localSet({userId: res.data})
          //如果确认保存密码
          if (this.data.remember) {
            storage.localSet({user: JSON.parse(JSON.stringify(this.data))})
          } else {
            storage.localDel("user")
          }
          localStorage.setItem("email", res.data.email)
          if(this.$route.path !== '/profile/dashboard'){
            this.$router.push("/profile/dashboard")
          }

        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img-fluid{
  width: 540px;
}

::v-deep a {
  //color: rgba(12,102,255,0.8);
  //color: #1D2C48;
  //  color: #0C66FF;
  //}
}

.form-floating {
  .invalid-feedback{
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }
  .form-control {

    &.password {
      &.is-invalid {
        margin-bottom: 3.5rem;
        //padding-right: calc(3.5em + 0.75rem);
        //background-position: right calc(2.8em + 0.1875rem) center;
        //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

    }

    &.is-invalid {
      margin-bottom: 1.65rem;
      background-image: none;
      border-color: var(--bs-form-invalid-border-color);
      //padding-right: calc(1.5em + 0.75rem);
      & ~ .invalid-feedback{
        display: block;
      }
    }

  }


  //background-repeat: no-repeat;
  //background-position: right calc(0.375em + 0.1875rem) center;
  //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
