import { createWebHistory, createRouter } from "vue-router";

import storage from "@/storage"

// const whitelist = ['/profile/dashboard',
//   '/profile/my-bills',
//   '/profile/deposit',
//   '/profile/withdraw',
//   '/profile/my-order',
//   '/profile/affiliates',
//   '/profile/settings']

const routes = [
  // {
  //   path: "/",
  //   redirect: '/home'
  // },

  {
    path: "/",
    name: "Home",
    component: () => import('../pages/n1/Home-1.vue'),
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import('../pages/Home.vue'),
  // },

  {
    path: "/7",
    name: "Homev7",
    component: () => import('../pages/Home-v7.vue'),
  },

  {
    path:'/product-details',
    name:'ProductDetail',
    component: () => import('../pages/n1/ProductDetail.vue'),
    props: true
  },
  {
    path:'/affiliates',
    name:'Affiliates',
    component: () => import('../pages/n1/Referral.vue'),
    props: true
  },
  {
    path:'/help-center',
    name:'HelpCenter',
    component: () => import('../pages/n1/HelpCenter'),
    props: true
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../pages/n1/AboutUs.vue')
  },

  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import('../pages/n1/BuyPlans.vue')
  },

  {
    path: '/special-contracts',
    name: 'Special Contracts',
    component: () => import('../pages/SpecialPlans.vue')
  },

  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../pages/n1/Terms')
  },

  {
    path: '/sign-in',
    name: 'Sign in',
    component: () => import('../pages/n1/Login.vue')
  },

  {
    path: '/sign-up',
    name: 'Sign up',
    component: () => import('../pages/n1/Register.vue')
  },

  {
    path: '/error',
    name: 'Error',
    component: () => import('../pages/Error.vue')
  },
  {
    path: '/pay-success',
    name: 'pay-success',
    component: () => import('../pages/pay-success')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../pages/n1/ForgotPassword.vue')
  },


  {
    path: '/what-is-an-nft',
    name: 'what is an nft',
    component: () => import('../pages/WhatIsAnNFT')
  },
  {
    path: '/what-is-cryptocurrency',
    name: 'what is cryptocurrency',
    component: () => import('../pages/n1/WhatIsCryptocurrency')
  },
  {
    path: '/what-is-web3',
    name: 'what is web3',
    component: () => import('../pages/n1/WhatIsWeb3')
  },
  {
    path: '/how-it-work',
    name: 'HowItWork',
    component: () => import('../pages/HowItWork')
  },

  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import('../pages/Reviews')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../pages/n1/Privacy')
  },
  {
    path:'/profile',
    name:'UserPanelLayout',
    component: () => import('../pages/n1/UserPanelLayout'),
    // redirect: '/profile/dashboard',
    children:[
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../pages/n1/Dashboard')
      },
      {
        path: 'my-bills',
        name: 'MyBills',
        component: () => import('../pages/n1/MyBills')
      },
      {
        path: 'deposit',
        name: 'Deposit',
        component: () => import('../pages/n1/Deposit')
      },
      {
        path: 'withdraw',
        name: 'Withdraw',
        component: () => import('../pages/n1/Withdraw')

      },
      {
        path: 'my-order',
        name: 'MyOrder',
        component: () => import('../pages/n1/MyOrder')
      },

      {
        path: 'affiliates',
        name: 'P-Affiliates',
        component: () => import('../pages/n1/Referrals')

      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../pages/n1/Setting')

      }

    ]
  },
// {
  //   path: '/home-v2',
  //   name: 'Home 2',
  //   component: () => import('../pages/Home-v2.vue'),
  // },
  // {
  //   path: '/home-v3',
  //   name: 'Home 3',
  //   component: () => import('../pages/Home-v3.vue')
  // },
  // {
  //   path: '/home-v4',
  //   name: 'Home 4',
  //   component: () => import('../pages/Home-v4.vue')
  // },
  // {
  //   path: '/home-v5',
  //   name: 'Home 5',
  //   component: () => import('../pages/Home-v5.vue')
  // },
  // {
  //   path: '/home-v6',
  //   name: 'Home 6',
  //   component: () => import('../pages/Home-v6.vue')
  // },
  // {
  //   path: '/home-v7',
  //   name: 'Home 7',
  //   component: () => import('../pages/Home-v7.vue')
  // },


  // {
  //   path:'/product-details-v1-:id',
  //   name:'ProductDetail_v1',
  //   component: () => import('../pages/ProductDetail-v1.vue'),
  //   props: true
  // },
  // {
  //   path:'/product-details-v2-:id',
  //   name:'ProductDetail_v2',
  //   component: () => import('../pages/ProductDetail-v2.vue'),
  //   props: true
  // },
  // {
  //   path:'/product-details-v3-:id',
  //   name:'ProductDetail_v3',
  //   component: () => import('../pages/ProductDetail-v3.vue'),
  //   props: true
  // },
  // {
  //   path: '/item-details',
  //   name: 'itemDetails',
  //   component: () => import('../pages/ItemDetails.vue')
  // },
  // {
  //   path: '/item-details-v2',
  //   name: 'itemDetailsV2',
  //   component: () => import('../pages/ItemDetails-v2.vue')
  // },
  // {
  //   path: '/explore',
  //   name: 'explore',
  //   component: () => import('../pages/Explore.vue')
  // },
  // {
  //   path: '/explore-v2',
  //   name: 'exploreV2',
  //   component: () => import('../pages/Explore-v2.vue')
  // },
  // {
  //   path: '/explore-v3',
  //   name: 'exploreV3',
  //   component: () => import('../pages/Explore-v3.vue')
  // },
  // {
  //   path: '/explore-v4',
  //   name: 'exploreV4',
  //   component: () => import('../pages/Explore-v4.vue')
  // },
  // {
  //   path: '/explore-v5',
  //   name: 'exploreV5',
  //   component: () => import('../pages/Explore-v5.vue')
  // },
  // {
  //   path: '/explore-v6',
  //   name: 'exploreV6',
  //   component: () => import('../pages/Explore-v6.vue')
  // },
  // {
  //   path: '/author',
  //   name: 'author',
  //   component: () => import('../pages/Author.vue')
  // },
  //
  // {
  //   path: '/activity',
  //   name: 'activity',
  //   component: () => import('../pages/Activity.vue')
  // },
  // {
  //   path: '/ranking',
  //   name: 'ranking',
  //   component: () => import('../pages/Ranking.vue')
  // },
  // {
  //   path: '/wallet',
  //   name: 'wallet',
  //   component: () => import('../pages/Wallet.vue')
  // },
  // {
  //   path: '/wallet-v2',
  //   name: 'wallet-v2',
  //   component: () => import('../pages/WalletTwo.vue')
  // },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: () => import('../pages/Blog.vue')
  // },
  // {
  //   path: '/blog-detail-:id',
  //   name:'NewsDetail',
  //   component: () => import('../pages/NewsDetail.vue'),
  // },
  // {
  //   path: '/blog-detail',
  //   name: 'blog-details',
  //   component: () => import('../pages/BlogDetail.vue')
  // },
  //
  // {
  //   path: '/login-v2',
  //   name: 'login v2',
  //   component: () => import('../pages/Login-v2.vue')
  // },
  //
  // {
  //   path: '/offers',
  //   name: 'Offer',
  //   component: () => import('../pages/Offers.vue')
  // },
  // {
  //   path: '/activity-v2',
  //   name: 'activity-v2',
  //   component: () => import('../pages/Activity-v2.vue')
  // },
  // {
  //   path: '/purchases-sales',
  //   name: 'purchases-sales',
  //   component: () => import('../pages/PurchasesSales.vue')
  // },
  // {
  //   path: '/transactions',
  //   name: 'transactions',
  //   component: () => import('../pages/Transactions.vue')
  // },
  // {
  //   path: '/display',
  //   name: 'display',
  //   component: () => import('../pages/Display.vue')
  // },
  // {
  //   path: '/redeem',
  //   name: 'redeem',
  //   component: () => import('../pages/Redeem.vue')
  // },
  // {
  //   path: '/deposit-enfties',
  //   name: 'deposit-enfties',
  //   component: () => import('../pages/DepositEnfties.vue')
  // },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import('../pages/Profile.vue')
  // },
  // {
  //   path: '/account',
  //   name: 'account',
  //   component: () => import('../pages/Account.vue')
  // },
  // {
  //   path: '/payment-methods',
  //   name: 'payment-methods',
  //   component: () => import('../pages/PaymentMethods.vue')
  // },
  // {
  //   path: '/seller-settings',
  //   name: 'seller-settings',
  //   component: () => import('../pages/SellerSettings.vue')
  // },
  // {
  //   path: '/notifications',
  //   name: 'notifications',
  //   component: () => import('../pages/Notifications.vue')
  // },
  // {
  //   path: '/security',
  //   name: 'security',
  //   component: () => import('../pages/Security.vue')
  // },
  // {
  //   path: '/create',
  //   name: 'create',
  //   component: () => import('../pages/Create.vue')
  // },
  // {
  //   path: '/create-single',
  //   name: 'create-single',
  //   component: () => import('../pages/CreateSingle.vue')
  // },
  // {
  //   path: '/create-multiple',
  //   name: 'create-multiple',
  //   component: () => import('../pages/CreateMultiple.vue')
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import('../pages/Contact.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.VUE_APP_BASE_URL,
  base: window.location.pathname,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        left: 0,
        top: 0
      }
    }
  }
});




const whitelist = [
    '/',
    '/product-details',
    '/affiliates',
    '/help-center',
    '/about-us',
    '/contracts',
    '/special-contracts',
    '/sign-in',
    '/sign-up',
    '/forgot-password',
    '/terms',
    '/privacy',
    '/error',
    '/what-is-an-nft',
    '/what-is-cryptocurrency',
    '/what-is-web3',

    // loginWhiteList
    '/profile/dashboard',
    '/profile/my-bills',
    '/profile/deposit',
    '/profile/withdraw',
    '/profile/my-order',
    '/profile/affiliates',
    '/profile/settings',

    '/pay-success'
]

const loginWhiteList = [
  '/profile/dashboard',
  '/profile/my-bills',
  '/profile/deposit',
  '/profile/withdraw',
  '/profile/my-order',
  '/profile/affiliates',
  '/profile/settings'
]

router.beforeEach((to, from, next) => {
  // if (to.path.endsWith('.html')) {
  //   next()
  // } else if (to.path.startsWith('/bitcoina')) {
  //   next('/bitcoina.html')
  // } else {
  //   next()
  // }


  if (whitelist.includes(to.path)) {
    if(loginWhiteList.includes(to.path)){
      if (storage.localGet("userId")) {
        next()
      }else{
        next('/sign-in')
      }
    }else{
      // console.log("to /home")
      next()
    }
  } else {
    next('/error')
    // next()
  }
})

export default router;
