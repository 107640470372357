<template>
  <!--  <TopGlobalTip ref="TopGlobalTip"></TopGlobalTip>-->
  <router-view :key="$route.path"/>
  <FooterBar></FooterBar>
</template>

<script>
import  FooterBar from "@/components/n1/FooterBar"
export default {
  name: 'App',
  components:{
    FooterBar
  },
  data() {
    return {
      param: undefined
    }
  },
  mounted() {
    this.setThemeColor('#7952b3')
  },
  created() {
    this.fetchRouteParams();
    this.fetchReferrer();
  },
  methods: {
    fetchRouteParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const s = urlParams.get('s');
      const legacyS = this.getLegacyQueryParam('s');
      const routeS = s || legacyS;
      // console.log('s:', routeS);
      this.param = routeS
    },
    getLegacyQueryParam(paramName) {
      const query = window.location.search.substr(1);
      const pairs = query.split('&');
      for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        if (decodeURIComponent(pair[0]) === paramName) {
          return decodeURIComponent(pair[1]);
        }
      }
      return null;
    },
    fetchReferrer() {
      let r = document.referrer.toLowerCase().replace('https://', '');
      if (r === '' || r === null) {
        r = window.location.hostname
      }

      console.log('ref:'+r)

      const urlParams = new URLSearchParams(window.location.search);
      const utmCampaign = urlParams.get('utm_campaign');
      const utmSource = urlParams.get('utm_source');

      if (utmCampaign === 'coinband') {
        if (utmSource === 'google') {
          sessionStorage.setItem('source', 'cb-google');
        } else if (utmSource === 'facebook') {
          sessionStorage.setItem('source', 'cb-facebook');
        }
      } else if (utmSource === 'taboola' || utmSource === '+Coingape' || utmSource === 'coingape') {
        sessionStorage.setItem('source', utmSource);
      }

      let para = this.param;
      let set = new Set([r]);
      if (typeof para === 'string' && para.length > 0) {
        if (para === 'coinband-google') {
          para = 'cb-google';
        }
        set = new Set([para]);
        if (para === 'chainwire-org') {
          set = new Set(['chainwire-org', r]);
        }
        if (para === 'blockchainwire-io') {
          const ref = urlParams.get('ref')
          if(ref) {
            set = new Set([ref]);
          } else {
            set = new Set(['blockchainwire-io']);
          }
        }
      }
      let source = Array.from(set);


      source = source.map(item => {
        if (item.includes('https://gdmining.com/?s=')) {
          return item.replace('https://gdmining.com/?s=', '');
        } else if (item.includes('https://gdmining.us/?s=')) {
          return item.replace('https://gdmining.us/?s=', '');
        } else if (item.includes('https://gdmining.uk/?s=')) {
          return item.replace('https://gdmining.uk/?s=', '');
        } else if (item.includes('https://gdmining.site/?s=')) {
          return item.replace('https://gdmining.site/?s=', '');
        } else if (item.slice(-1) === '/') {
          return item.slice(0, -1);
        } else if (item.includes('/?s=')) {
          return item.split('/?s=')[1];
        } else if (item.includes('https://')) {
          return item.replace('https://', '')
        } else if (item.includes('gdmining.com') && item.includes('?__cf_chl_tk=') ) {
          return 'gdmining.com'
        }
        return item;
      });

      if (!sessionStorage.getItem('source')) {
        sessionStorage.setItem('source', source.join(' / '));
      }

    },


    // 变量前缀
    // colorChange(e) {
    //   // e就是选择了的颜色
    //   const pre = "--el-color-primary";
    //   // 白色混合色
    //   const mixWhite = "#ffffff";
    //   // 黑色混合色
    //   const mixBlack = "#000000";
    //   const el = document.documentElement;
    //   el.style.setProperty(pre, e);
    //   // 这里是覆盖原有颜色的核心代码
    //   for (let i = 1; i < 10; i += 1) {
    //     el.style.setProperty(`${pre}-light-${i}`, mix(e, mixWhite, i * 0.1));
    //   }
    //   el.style.setProperty("--el-color-primary-dark", mix(e, mixBlack, 0.1));
    // },
    setThemeColor(color) {
      const el = document.documentElement;
      const body = document.querySelector("body");
      // const nprogress = document.querySelector("#nprogress .bar");
      // console.log(nprogress)
      el.style.setProperty("--el-color-primary", color);
      body.style.setProperty("--van-primary-color", color);
      // nprogress.style.setProperty("background", color);
      // 此行判断是否为白天/暗夜模式，可根据自身业务调整代码
      // let mixColor = Store.state.settings.sideTheme.split("-")[1] === "light" ? "#ffffff" : "#000000";
      // 此行判断是否为白天/暗夜模式，可根据自身业务调整代码
      // for (let i = 1; i < 10; i++) {
      //   el.style.setProperty(`--el-color-primary-light-${i}`, colourBlend(color, mixColor, i / 10));
      //   el.style.setProperty(`--el-color-primary-dark-${i}`, colourBlend(color, mixColor, i / 10));
      // }
      // el.style.setProperty(`--el-color-primary-dark-2`, colourBlend(color, mixColor, 0.2));

    },
    colourBlend(c1, c2, ratio) {
      ratio = Math.max(Math.min(Number(ratio), 1), 0)
      let r1 = parseInt(c1.substring(1, 3), 16)
      let g1 = parseInt(c1.substring(3, 5), 16)
      let b1 = parseInt(c1.substring(5, 7), 16)
      let r2 = parseInt(c2.substring(1, 3), 16)
      let g2 = parseInt(c2.substring(3, 5), 16)
      let b2 = parseInt(c2.substring(5, 7), 16)
      let r = Math.round(r1 * (1 - ratio) + r2 * ratio)
      let g = Math.round(g1 * (1 - ratio) + g2 * ratio)
      let b = Math.round(b1 * (1 - ratio) + b2 * ratio)
      r = ('0' + (r || 0).toString(16)).slice(-2)
      g = ('0' + (g || 0).toString(16)).slice(-2)
      b = ('0' + (b || 0).toString(16)).slice(-2)
      return '#' + r + g + b
    }
  }

}
</script>
