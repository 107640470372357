<template>
    <div class="header-main is-sticky" :class="isTransparent">
        <div class="container">
            <div class="header-wrap">
              <div class="d-flex align-items-center">                <!-- logo -->
                <Logo></Logo>
                <!--              Language-->
                <Language></Language>
              </div>
                <!-- mobile action -->
                <MobileAction></MobileAction>
                <!-- heder search -->
<!--                <HeaderSearch></HeaderSearch>-->
                <!-- Menu -->
                <Menu classname="btn-dark"></Menu>
                <div class="header-overlay"></div>
            </div><!-- .header-warp-->
        </div><!-- .container-->
    </div><!-- .header-main-->
</template>
<script>
// @ is an alias to /src
import Logo from '@/components/common/Logo.vue'
import MobileAction from '@/components/common/MobileAction.vue'
import HeaderSearch from '@/components/common/HeaderSearch.vue'
import Menu from '@/components/common/Menu.vue'
import storage from "@/storage";
import Language from "@/components/common/Language"
export default {
  name: 'HeaderMain',
  props: ['isTransparent'],
  data(){
    return {
      login: false
    }
  },
  components: {
    Logo,
    MobileAction,
    HeaderSearch,
    Menu,
    Language
  },
  mounted() {
    let userId = storage.localGet("userId")
    if(userId){
      this.login = true
    }
  },
  methods:{

  }
}
</script>
