<template>
  <div class="product" @mouseenter="mouseenter"  @mouseleave="mouseleave">
    <div class="row g-3 g-md-6">
      <div class="el-col-11_5 ">
        <div class="product-image">
          <img :src="product.presignedUrl" class="product-img-top"  v-if="ImageData[index]" :alt="ImageData[index].alt" :title="ImageData[index].title">
          <img :src="product.presignedUrl" class="product-img-top"  alt="" title="" v-else>
          <div class="sell_out" v-if="product.progress === 100">
            <img src="@/images/tmp/sold-out.png" alt="">
          </div>
        </div>
      </div>
      <div class="el-col-12_5">
        <div class="row g-2 g-md-4" style="width: 100%;height: 100%;">
          <div class="col-6 info-item">
            <div class="price-title">Contract Term</div>
            <div class="price-value">
              {{
                 getDate(product.period )
              }}
            </div>
          </div>
          <div class="col-6 info-item" style="text-align: right">
            <div class="price-title">Contract Price</div>
            <div class="price-value">${{ product.amount }}</div>
          </div>
          <div class="col-12 info-item">
            <div class="price-title">Daily Rewards</div>
            <div class="price-value">{{ ((product.amount * product.percent)/bitCoin).toFixed(8) }}  BTC ( ≈ ${{ (product.amount * product.percent).toFixed(2) }} )</div>
          </div>
<!--          <template v-if="product.limitTime>1">-->
            <div class="col-12 info-item" v-if="product.showType===1">
              <div class="price-title">Monthly revenue:</div>
              <div class="price-value">{{((product.amount * product.percent * 30)/bitCoin).toFixed(8) }} BTC ( ≈ $ {{ (product.amount * product.percent * 30).toFixed(2) }} )</div>
            </div>

            <div class="col-12 info-item" v-if="product.showType===2">
              <div class="price-title">{{ product.period }} Days Fixed Rewards:</div>
              <div class="price-value">${{product.amount }} + ${{ (product.amount * product.percent * product.period).toFixed(2)}}</div>
            </div>
<!--          </template>-->
          <div class="col-12 info-item" v-if="product.showType===3">
            <div class="price-title">1 Day Fixed Rewards:</div>
            <div class="price-value">${{product.amount }} + ${{ (product.amount * product.percent * product.period).toFixed(2)}}</div>
          </div>
          <div class="col-12 info-item" >
            <div class="price-title">Capital Back</div>
            <div class="price-value">Yes</div>
          </div>
        </div>
      </div>
<!--      <div class=""></div>-->
    </div>
    <div class="row g-2 g-md-4" >
      <div class="el-col-11_5 product-title">
        <div>{{ product.name }}</div>
<!--        <div style="margin-top: 0.1875rem">${{ product.amount }} / {{   getDate(product.period) }}</div>-->
<!--        d-sm-none-->

        <div class="progress-wrap">
          <div class="left"> Volume</div>
          <div class="right">
            <div class="progress-box">
              <!--            <b-progress :max="100" animated>-->
              <!--              <b-progress-bar :value="product.progress" :variant="product.progress === 100 ? 'danger' : ''">-->
              <!--                <span><strong>{{ product.progress }}%</strong></span>-->
              <!--              </b-progress-bar>-->
              <!--            </b-progress>-->
              <div class="progress" role="progressbar" aria-label="Animated striped example" :aria-valuenow="product.progress" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar progress-bar-striped progress-bar-animated" :class="[product.progress === 100 ? 'bar-color_over' : 'bar-color_proceed']" :style="{width: product.progress + '%'}">{{product.progress}}%</div>
              </div>
            </div>

          </div>
        </div>
<!--        <div class="sold-out-content">(Sold out at 100%)</div>-->
      </div>
      <div class="el-col-12_5 " >
<!--        <div class="sell_out" v-if="product.progress === 100">-->
<!--          <img src="../../images/sell_out_icon.png" alt="">-->
<!--        </div>-->
        <div class="product-but d-flex align-items-center">
<!--          btn-dark d-block-->
          <button href="#" @click="buyNow" :class="[orderNowClass, ]" :style="{opacity: product.progress === 100 ? 0 : 1}" v-if="isRelated"  class="btn " >Buy Now</button>
<!--          <ButtonLink @click="buyNow" text="Order Now" link="/wallet" :classname="'btn  ' + orderNowClass  " ></ButtonLink>-->
          <button href="#" @click="seeMoreClick" :class="[seeMore, ]"  class="btn " style="margin-left: 0.375rem">See More</button>
<!--          <ButtonLink text="See More" link="/product-details-1" :classname="'btn ' + seeMore" style="margin-left: 0.375rem"></ButtonLink>-->
        </div>
      </div>
<!--      d-sm-flex-->
      <div class="col-12  progress-wrap d-none">
        <div class="left"> Progress </div>
        <div class="right">
          <div class="progress-box">
<!--            <b-progress :max="100" animated>-->
<!--              <b-progress-bar :value="product.progress" :variant="product.progress === 100 ? 'danger' : ''">-->
<!--                <span><strong>{{ product.progress }}%</strong></span>-->
<!--              </b-progress-bar>-->
<!--            </b-progress>-->
            <div class="progress" role="progressbar" aria-label="Animated striped example" :aria-valuenow="product.progress" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-bar progress-bar-striped progress-bar-animated" :class="[product.progress === 100 ? 'bar-color_over' : 'bar-color_proceed']" :style="{width: product.progress + '%'}">{{product.progress}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BuyNow :plan="product" :showBuyNow="showBuyNow" @buyNowModal="buyNowModal"></BuyNow>
  </div><!-- end card -->
</template>
<script>
// import indexApi from "@/apis/indexApi";
import {createPopper} from '@popperjs/core';
import date from "@/date"
import storage from "@/storage";
import BuyNow from "@/components/common/BuyNow";
// import  store from "@/store"
export default {
  name: 'Products',
  props: ['product', 'index','bitCoin','isRelated'],
  // filters: {
  //   getDate(data) {
  //     return date.getDate(data);
  //   }
  // },
  components: {BuyNow},
  data(){
    return {
      orderNowClass: 'btn-dark',
      seeMore: 'btn-outline-dark',
      PlanData: {},
      Show: false,
      showBuyNow: false,
      // plan: undefined
      specialSales: [
        'Labor Day Special Sale',
        'Christmas Sale'
      ],
      ImageData: [
        // {alt: 'cloud mining technology Services', title:'cloud mining technology Services'},
        // {alt: 'cloud mining service provider', title:'cloud mining service provider'},
        // {alt: 'Best Free Cloud Mining Sites', title:'best cloud mining contracts'},
        // {alt: 'best cloud mining site', title:'new energy cloud mining company'},
        // {alt: 'trusted cloud mining sites', title:'Best Free Cloud Mining Sites'},
        // {alt: 'Trusted Free Cloud Mining Sites', title:'best cloud mining site'}
      ]
    }
  },
  created() {
    // indexApi.GetBitcoin(
    //     res => {
    //       // alert(res.data)
    //       this.bitCoin = res.data
    //     }
    // )
  },
  mounted() {

    /*============= Custom Tooltips =============== */
    function customTooltip(selector, active) {
      let elem = document.querySelectorAll(selector);
      if (elem.length > 0) {
        elem.forEach(item => {
          const parent = item.parentElement;
          const next = item.nextElementSibling;
          createPopper(item, next);
          parent.addEventListener("mouseenter", function () {
            parent.classList.add(active)
          });
          parent.addEventListener("mouseleave", function () {
            parent.classList.remove(active)
          });
        });
      }
    }

    customTooltip('.custom-tooltip', 'active');

  },

  methods: {
      getDate(data) {
        return date.getDate(data);
      },
    buyNow() {
        if(this.product.progress === 100){
          return;
        }
      let userId = storage.localGet("userId")
      if (!userId) {
        this.$router.push("/sign-in")
      } else {
        this.PlanData = this.product
        this.showBuyNow = true
      }

    },
    seeMoreClick(){
      // store.dispatch("store/setProducts", this.product);
      window.location.href = `/product-details?id=${this.product.id}`;
      // this.$router.push(`/product-details?id=${this.product.id}`)
    },
    // mouseenter(){
    //   this.orderNowClass = 'btn-outline-dark'
    // },
    // mouseleave(){
    //   this.seeMore = 'btn-dark'
    // }
    buyNowModal() {
      console.log('child showBuyNow:' + this.showBuyNow)
      this.showBuyNow = false
      console.log('showBuyNow change:' + this.showBuyNow)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .bar-color{
  &_proceed{
    background-color:#1C2A46 !important;
  }
  &_over{
    background-color: #FF5959!important;
  }
}
.product{
  width: 100%;
  padding: 0.625rem 0.625rem 1.25rem;
//height: 235px;
  background: #FFFFFF;
  box-shadow: 0px 0.125rem 0.1875rem 0px rgba(0,0,0,0.03);
  border-radius: 0.5rem;
  transition: all 0.3s ease;

  .product-image{
    overflow: hidden;
    border-radius: 0.4375rem;
    position: relative;
    .product-img-top{
      //width: 11.1875rem;
      //width: auto;
      //height: 7.375rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease;

      //transform: scale(1.1);
    }
    .sell_out{
      position: absolute;
      width:100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      background-color: rgba(0,0,0,0.5);
      justify-content: center;
      img{
        height: 6rem;
      }
    }

  }

  .info-item{
    .price-title{
      //font-size: 0.625rem;
      font-size: 0.965rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8492A6;
      line-height: 0.875rem;
    }
    .price-value{
      //font-size: 0.875rem;
      font-size: 1.1rem;
      //font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #273444;
      line-height: 1.25rem;
      margin-top: 10px;
    }
  }
  .product-title{
    //font-size: 0.875rem;
    margin-top: 50px;
    font-size: 1.2rem;
    //font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #273444;
    line-height: 1.25rem;
  }

  .product-but{
    height: 100%;
    .btn{
      padding: 0.4375rem 0.375rem;
    }
  }
  //.sell_out{
  //  text-align: center;
  //  img{
  //    height: 2.3125rem;
  //  }
  //
  //}

  &:hover{
    //background: #1C2A46;
    background: orange;
    box-shadow: 0px 0.125rem 0.1875rem 0px rgba(0,0,0,0.03);
    .product-img-top{
      transform: scale(1.1);
    }

    .product-title, .price-title, .price-value, .progress-wrap .left{
      color: #ffffff !important;
    }

    .product-but{
      .btn-dark{
        background-color: #ffffff;
        border-color: #ffffff;
        color: #1C2A46;
        &:hover{
          border-color: #ffffff;
          background-color: #1C2A46;
          color: #ffffff;
        }
      }
      .btn-outline-dark{
        border-color: #ffffff;
        background-color: #1C2A46;
        color: #ffffff;
        &:hover{
          background-color: #ffffff;
          border-color: #ffffff;
          color: #1C2A46;
        }
      }
    }

  }
  //.col-sm-6{
  //  &.product-but {
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    ::v-deep .but:last-child{
  //      margin-left: 1rem;
  //    }
  //  }
  //}


  .progress-wrap{
    display: flex;
    align-items: center;
    margin-top: 0.625rem;
    .left{
      //height: 14px;
      font-size: 0.665rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #273444;
      //color: #8492A6;
      line-height: 0.875rem;
    }
    .right{
      flex: 1;
      width: 0;
      margin-left: 0.875rem;
      .progress-box{
        height: 1.25rem;
        position: relative;
        overflow: hidden;
        .progress{
          height: 100%;
          background: #E9ECEF;
        }

      }
    }

  }
}

.sold-out-content{
  color: #8492A6;
  font-size: 0.9rem;
  margin-top:3px;
}

@media screen and (max-width: 321px){
  .product {
    .info-item{
      .price-title{
        font-size: 0.67rem;
        line-height: 0.6rem;
      }
      .price-value{
        font-size: 0.7rem;
        line-height: 0.9rem;
        margin-top: 2px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 0.82rem;
      line-height: 1.25rem;
      margin-top: 3px;
    }
    .product-but{
      .btn{
        padding: 0.32rem 0.14rem;
        font-size: 0.725rem;
      }
    }


    .progress-wrap{
      .left{
        font-size: 0.7rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 0.8rem;
        }
      }
    }
  }
}


@media screen and (max-width: 405px){
  .product {
    .info-item{
      .price-title{
        font-size: 0.7rem;
        line-height: 0.7rem;
      }
      .price-value{
        font-size: 0.8rem;
        line-height: 1rem;
        margin-top: 2px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 0.9rem;
      line-height: 1rem;
      margin-top: 3px;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 0.8rem;
      }
    }

    .progress-wrap{
      .left{
        font-size: 0.7rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 0.8rem;
        }
      }
    }
  }
}


@media screen and (max-width: 476px){

}



@media (max-width: 576px){
  .product {
    .info-item{
      .price-title{
        font-size: 0.875rem;
        line-height: 0.9rem;
      }
      .price-value{
        font-size: 0.9rem;
        line-height: 0.9rem;
        margin-top: 1px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 1rem;
      line-height: 1rem;
      margin-top: 1rem;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 0.8rem;
      }
    }
    .progress-wrap{
      .left{
        font-size: 0.825rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 1.1rem;
        }
      }
    }
  }
}

@media (min-width: 600px){
  .product {
    .info-item{
      .price-title{
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
      .price-value{
        font-size: 1.1rem;
        line-height: 1.5rem;
        margin-top: 6px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 1.2rem;
      line-height: 1rem;
      margin-top: 1rem;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 1rem;
      }
    }
    .product-image{
      .sell_out{
        text-align: center;
        img{
          height:6rem;
        }
      }
    }

    .progress-wrap{
      .left{
        font-size: 1rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 1.3rem;
        }
      }
    }
  }
  .sold-out-content{
    color: #8492A6;
    font-size:1rem;
    margin-top:3px;
  }
}



@media (min-width: 630px){
  .product {
    .info-item{
      .price-title{
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
      .price-value{
        font-size: 1.1rem;
        line-height: 1.5rem;
        margin-top: 6px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 1.2rem;
      line-height: 1rem;
      margin-top: 1rem;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 1rem;
      }
    }
    .product-image{
      .sell_out{
        text-align: center;
        img{
          height:6rem;
        }
      }
    }

    .progress-wrap{
      .left{
        font-size: 1rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 1.3rem;
        }
      }
    }
  }
  .sold-out-content{
    color: #8492A6;
    font-size:1rem;
    margin-top:3px;
  }

}



@media (min-width: 768px){
  .product {
    .info-item{
      .price-title{
        font-size: 1.3rem;
        line-height: 1.3rem;
      }
      .price-value{
        font-size: 1.6rem;
        line-height: 1.5rem;
        margin-top: 6px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 1.3rem;
      line-height: 1.2rem;
      margin-top: 2rem;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 1.2rem;
      }
    }
    .product-image{
      .sell_out{
        text-align: center;
        img{
          height:6rem;
        }
      }
    }

    .progress-wrap{
      .left{
        font-size: 1.08rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 1.5rem;
        }
      }
    }
  }
  .sold-out-content{
    color: #8492A6;
    font-size:1rem;
    margin-top:3px;
  }

}

@media (min-width: 992px){
  .product {
    .info-item{
      .price-title{
        font-size: 0.9rem;
        line-height: 0.8rem;
      }
      .price-value{
        font-size: 1rem;
        line-height: 1.2rem;
        margin-top: 2px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 1rem;
      line-height: 1rem;
      margin-top: 16px;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 0.825rem;
      }
    }
    .product-image{
      .sell_out{
        text-align: center;
        img{
          height:6rem;
        }
      }
    }

    .progress-wrap{
      .left{
        font-size: 0.85rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 1rem;
        }
      }
    }
  }
}


@media (min-width: 1200px){
  .product {
    .info-item{
      .price-title{
        font-size: 0.925rem;
        line-height: 1rem;
      }
      .price-value{
        font-size: 1.1rem;
        line-height: 1.5rem;
        margin-top: 3px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 1.1rem;
      line-height: 1rem;
      margin-top: 2.3rem;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 1rem;
      }
    }
    .product-image{
      .sell_out{
        text-align: center;
        img{
          height:8rem;
        }
      }
    }
    .progress-wrap{
      .left{
        font-size: 0.9rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 1.2rem;
        }
      }
    }
  }

  //.sold-out-content{
  //  color: #8492A6;
  //  font-size:1rem;
  //  margin-top:3px;
  //}
}

@media (min-width: 1500px){
  .product {
    .info-item{
      .price-title{
        font-size: 1rem;
        line-height: 0.9rem;
      }
      .price-value{
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-top: 2px;
      }
    }

    .product-title{
      //font-size: 0.875rem;
      font-size: 1.1rem;
      line-height: 1rem;
      margin-top: 2.2rem;
    }
    .product-but{
      .btn{
        padding: 0.5rem 0.3rem;
        font-size: 1rem;
      }
    }
    .product-image{
      .sell_out{
        text-align: center;
        img{
          height:8rem;
        }
      }
    }

    .progress-wrap{
      .left{
        font-size: 0.8rem;
      }
      .right{
        margin-left: 0.2rem;
        .progress-box{
          height: 1rem;
        }
      }
    }
  }
}




.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.author-link,
.card-price-wrap {
  z-index: 2;
  position: relative;
}
</style>
